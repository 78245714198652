/*!

=========================================================
Lets Make Websites Great Again 
=========================================================

CQ Solutions

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/


// Core Components
@import "core/variables";
@import "core/mixins";
@import "core/fileupload";
@import "core/keyframes";

// Core Plugins
@import "core/misc";

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-image-gallery";
@import "plugins/plugin-react-slick";
@import "plugins/plugin-react-tagsinput";
